import React from "react";

import iconComment from "../../images/icon-comment.png";
import ChatButton from "./chatBtn";

const ClaimButton = ({ onHide }) => {
  return (
    <>
      <div
        className="btn btn-claim modal-btn"
        id="btn-claim"
        role="button"
        tabIndex={0}
      >
        <figure className="mb-0 icon-comment">
          <img src={iconComment} alt="Icon Comment" className="img-fluid" />
        </figure>
        <span className="text-uppercase link">
          <ChatButton innerText="Chat with an agent now" onHide={onHide} />
        </span>
      </div>
    </>
  );
};

export default ClaimButton;
