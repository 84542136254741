import React from "react";
import iconArrowRight from "../../images/icon-arrow-right.png";

const ChatButton = ({ innerText }) => {
  const openChatHandler = () => {
    // chatBtn.click()
    let chatBtn;
    if (document.querySelector("df-messenger-chat-bubble")) {
      chatBtn = document.querySelector("df-messenger-chat-bubble");
      chatBtn.shadowRoot.querySelector("button").click();
    }
  };
  return (
    <>
      <div
        className="d-inline-flex justify-content-center align-items-center"
        onClick={openChatHandler}
        onKeyDown={openChatHandler}
        role="button"
        tabIndex={0}
      >
        {innerText}
        <img
          className="ms-2 icon-arrow-right"
          src={iconArrowRight}
          alt="Icon Arrow Right"
        />
      </div>
    </>
  );
};

export default ChatButton;
