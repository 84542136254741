import React, { useEffect } from "react";
import Layout from "../../components/layout";
import Landing from "../../components/landing/landing";
import Seo from "../../components/seo";
import Helmet from "react-helmet";

const IndexPage = (props) => {
  console.log(props);
  // useEffect(() => {
  //   if (props.path.startsWith("/")) {
  //     document.querySelector("body").classList.add("rightToLeft");
  //   }
  // }, []);
  return (
    <>
      <Layout>
        <Seo title="Home | Snickers Hunger Insurance" lang="en" />
        {/* Campaign is On */}
        <Landing />
        {/* <df-messenger
          project-id="snickersinsurancechatbot2-ixwh"
          agent-id="55748fc3-8c30-4119-9c6c-f5c20ece0375"
          language-code="ar"
        >
          <df-messenger-chat-bubble chat-title="Peggy"></df-messenger-chat-bubble>
        </df-messenger> */}
        {/* Campaign Closed */}
        {/* <CampaignClosed /> */}
        <Helmet>
          {" "}
          <script src="https://www.gstatic.com/dialogflow-console/fast/messenger/bootstrap.js?v=1"></script>
        </Helmet>

        <df-messenger
          intent="WELCOME"
          chat-title="SnickersInsuranceChatbot"
          agent-id="b82be01c-2c46-40b8-b515-b428454db79a"
          language-code="en"
        ></df-messenger>
      </Layout>
    </>
  );
};

export default IndexPage;
