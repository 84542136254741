import React from "react";
import ClaimButton from "./claimButton";

// const Claim = () => {
//   return (
//     <>
//       <section className="text-center">
//         <ClaimButton />
//       </section>
//     </>
//   )
// }
const Claim = ({ onHide }) => {
  return (
    <>
      <section className="text-center modal-section">
        <ClaimButton onHide={onHide} />
      </section>
    </>
  );
};
export default Claim;
