import React from "react"
import ChatButton from "../chatBtn"
import iconRageQuit from "../../../images/en/icon-rage-quit.png"

const RageQuitTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="rage-quit"
        role="tabpanel"
        aria-labelledby="rage-quit-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-rage-quit.png"
              alt="Rage Quit"
              quality={100}
            /> */}
            <img src={iconRageQuit} alt="Rage Quit" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Rage Quit</h3> */}
          <p>
            Did you smash something or someone in an uncontrollable game-related
            hissy fit? We don't cover broken items or physical damage. But we
            can help you prevent hunger-induced rage quitting in the future. And
            maybe recommend some anger management classes?
          </p>
          <div className="text-center">
            <span className="text-uppercase link">
              <ChatButton innerText="Chat with an agent now" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default RageQuitTab
