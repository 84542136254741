import React from "react"
import ChatButton from "../chatBtn"
import iconLootLoss from "../../../images/en/icon-loot-loss.png"

const LootLossTab = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="loot-loss"
        role="tabpanel"
        aria-labelledby="loot-loss-tab"
      >
        <div className="d-flex flex-column justify-content-center h-100">
          <figure className="mb-0 icon-main">
            {/* <StaticImage
              src="../../../images/icon-loot-loss.png"
              alt="Loot Loss"
              quality={100}
            /> */}
            <img src={iconLootLoss} alt="Loot Loss" className="img-fluid" />
          </figure>
          {/* <h3 className="text-uppercase">Loot Loss</h3> */}
          <p>
            Did you finally get your hands on a legendary item, only to lose it
            moments later because you fell from a cliff while you were
            celebrating your good fortune? We offer real benefits to people who
            lose things of great imaginary value for stupid, hunger-related
            reasons.
          </p>
          <div className="text-center">
            <span className="text-uppercase link">
              <ChatButton innerText="Chat with an agent now" />
            </span>
          </div>
        </div>
      </div>
    </>
  )
}

export default LootLossTab
